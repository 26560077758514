(function(window) {
  var $window = $(window);

  function init() {
    breakpoint();
    // editonGallery();

  }

  function editonGallery() {
    var owlGallery = $(".gallery-images").owlCarousel({
      items: 1,
      dots: false,
      margin: 0,
      nav: false,
      mouseDrag: false,
      loop: true,
      smartSpeed: 800,
      onInitialized: function(event) {
        var text = $(".owl-item.active .image-box").attr("data-caption");
        if (text) {
          artistNameChange(text);
        } else {
          artistNameChange("");
        }
      }
    });

    owlGallery.on("changed.owl.carousel", function(event) {
      setTimeout(function() {
        var text = $(".owl-item.active .image-box").attr("data-caption");
        artistNameChange(text);
      }, 800);
    });

    owlGallery.on("initialize.owl.carousel", function(event) {
      console.log("init");
    });

    $(".edition-next,.gallery-images .image-box").click(function() {
      owlGallery.trigger("next.owl.carousel");
    });

    $(".edition-prev").click(function() {
      owlGallery.trigger("prev.owl.carousel");
    });

    $(".current-edition").on("click", function() {
      $(".editions-list").toggleClass("show");
    });
  }

  function artistNameChange(text) {
    $(".edition-artist")
      .empty()
      .html(text);
    $(".edition-artist a").click(function(e) {
      e.preventDefault();
      window.open(this.href);
    });
  }

  function breakpoint() {
    var breakpoint;
    var breakpoint_refreshValue;
    breakpoint_refreshValue = function() {
      window.breakpoint = window
        .getComputedStyle(document.querySelector("html"), ":before")
        .getPropertyValue("content")
        .replace(/\"/g, "");
    };

    $(window)
      .resize(function() {
        breakpoint_refreshValue();
      })
      .resize();
  }

  window.Application = {
    init: init
  };
})(window); // Self execute

Application.init();
